#carousel-indicator-buttons {
    position: relative;
    top: 26vh;

    @apply flex size-fit justify-center mx-auto gap-2 p-2
}

.carousel-card-wrapper {
    overflow: hidden;
    position: relative;
    top: auto;
    left: auto;
    width: 50vw;

    @apply rounded-2xl 
}

.carousel-card-wrapper::before {
    overflow: hidden;
    background: conic-gradient(oklch(var(--p)) 0deg,
            rgba(252, 70, 106, 1) 0deg,
            transparent 80deg);
    animation: border-spin 7s linear infinite;

    @apply absolute h-[400%] w-[400%] content-[''] left-[-150%] top-[-150%]
}

.carousel-card-content {
    overflow: hidden;
    background-color: rgba(19, 11, 48, 1);
    @apply absolute w-[calc(100%-8px)] h-[calc(100%-8px)] top-[4px] left-[4px] rounded-2xl
}

.carousel {
    overflow: hidden;
    position: relative;
    top: 25vh;
    left: 20vw;
    width: 60vw;
    height: 55vh;
}

@keyframes border-spin {
    100% {
        transform: rotate(-360deg);
    }
}