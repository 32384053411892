@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400..900&display=swap');

.section {
    width: 100%;
    height: 100vh;
    background-size:  cover;
    scroll-snap-align: start;
    font-family: "Tektur", sans-serif;
}

.sectionHeader {
  position: relative;
  top: 20vh;
  font-weight: 900;
}